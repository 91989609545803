// const BaseURL = 'https://api.evsocial.in/api';
const BaseURL = process.env.NEXT_PUBLIC_APP_API_URL;



export const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};


export const Get_Waiting_Period = `${BaseURL}/general/waiting/period`;
export const Get_Cities = `${BaseURL}/general/city`;
export const Get_Cities_General = `${BaseURL}/general/list-city-web?`;
export const Get_States = `${BaseURL}/general/list-state-web?`;
export const Get_Companies = `${BaseURL}/home/vehicle/filter`;
export const Get_Vehicle_Details = `${BaseURL}/home/vehicle/detail`;
export const Get_Upcoming_Vehicles = `${BaseURL}/home/vehicle/upcoming/random`;
export const Load_User = `${BaseURL}/user/detail`;
export const User_EV_Journey = `${BaseURL}/user/detail/ev/journey`;
export const compare_vehicle = `${BaseURL}/home/vehicle/compare?vehicle=`;
export const get_brochure_url = `${BaseURL}/vehicle/download/brochure/`;
export const Get_Community_Details = `${BaseURL}/home/community/forum`;
export const company_listing = `${BaseURL}/home/company`;
export const company_post_listing = `${BaseURL}/home/company/post?company=`;
export const Get_Random_Companies = `${BaseURL}/home/company/random`;
export const Get_All_Company_List = `${BaseURL}/home/company`;
export const Get_Vehicles_By_Company = `${BaseURL}/general/vehicle`;
export const Get_Vehicles_Color_List=`${BaseURL}/vehicle/list-color-variants`;
export const Get_Company_Details = `${BaseURL}/company/details/web`;
export const User_Posts = `${BaseURL}/user/detail/post`;
export const User_Reviews = `${BaseURL}/user/detail/review`;
export const Dealer_Reviews = `${BaseURL}/user/detail/dealer/review`;
export const Get_Home_Upcoming_Vehicles_list = `${BaseURL}/home/upcoming-vehicles/v2`;
export const Get_Upcoming_Vehicles_list = `${BaseURL}/home/vehicle/upcoming`;
export const Get_All_Specifications = `${BaseURL}/general/specification`;
export const Send_Vehicle_Inquiry = `${BaseURL}/vehicle-inquiry`;
export const Get_Vehicle_Variants = `${BaseURL}/vehicle/drop-down/`;
export const Add_User_Info = `${BaseURL}/user/filter/add`;
export const Get_Company_Infrastructure = `${BaseURL}/CompanyInfrastructure/`;
export const Get_User_Timeline = `${BaseURL}/user/detail/timeline`;
export const Get_Owned_Vehicles = `${BaseURL}/user/detail/owned/vehicle?id=`;
export const Add_User_Car_Reviews = `${BaseURL}/user/car/review/web`;
export const Add_User_Blog = `${BaseURL}/user/blog/web`;
export const Save_vehicle = `${BaseURL}/home/vehicle/save_vehicle`;
export const Add_User_Timeline_Upload = `${BaseURL}/user/timeline/upload`;
export const Notify_Me = `${BaseURL}/notify-me`;
export const Subscribe = `${BaseURL}/subscribe`;
export const State = `${BaseURL}/general/state`;
export const city = `${BaseURL}/general/city?state=`;
export const DealersByVehicleId = `${BaseURL}/dealer/vehicle/all-dealers`;
export const DealerDeatailsById = `${BaseURL}/dealers/details/`;
export const NearbyDealers = `${BaseURL}/dealer/vehicle/nearby-dealers`;
export const vehiclesBydealerId = `${BaseURL}/dealer/vehicle/all-web?dealer=`;
export const brandsBydealersId = `${BaseURL}/dealer/vehicle/all-web/company?dealer=`;
export const dealersByBrandId = `${BaseURL}/dealer/vehicle/all-dealers/web`
export const dealercountWithCity = `${BaseURL}/dealer/vehicle/all-dealers-by-city`
export const getTopTenCompanies = `${BaseURL}/home/popular-brands/v2`;
export const getVehicleListReview = `${BaseURL}/user/car/review/list/web?vehicle=`;
export const addQuestionVehicle = `${BaseURL}/vehicle-qna/question/add`;
export const listQuestionVehicle = `${BaseURL}/vehicle-qna/question/list-qna?`;
export const addAnsToQueVehicle = `${BaseURL}/vehicle-qna/ans/add`;
export const getListOfAnsOfQue = `${BaseURL}/vehicle-qna/ans/list?questionId=`;
export const VehicleOverviewList = `${BaseURL}/vehicle-overview/details/web/`;
export const similarNewsList=`${BaseURL}/tags/similar-news/web?`
export const getDealerBrandsList=`${BaseURL}/dealer-brands/getBrand/`

// export const getTopTenCompanies = `${BaseURL}/company/getToptenCompany`;
export const user_signUp = `${BaseURL}/user/signup`;
export const user_Login = `${BaseURL}/user/login`;
export const forgot_password = `${BaseURL}/user/generate-otp`;
export const verify_otp = `${BaseURL}/user/verify-otp`;
export const reset_password = `${BaseURL}/user/forget-password/`;
export const reset_username = `${BaseURL}/user/forget-username/`;
export const edit_user = `${BaseURL}/user/update/`;
export const delete_user = `${BaseURL}/user/web/`;
export const UsersByVehicleId = `${BaseURL}/user/owned/vehicle/web?vehicle=`;
export const get_other_user = `${BaseURL}/user/other-profile/`;
export const get_other_user_by_slug = `${BaseURL}/user/other-profile/slug/`;
export const get_user_vehicles = `${BaseURL}/user/detail/owned/vehicle?id=`
export const get_cms = `${BaseURL}/cms/website/`;
export const get_similiar_vehicle =`${BaseURL}/vehicle/similiar?`
export const get_vehicle_range = `${BaseURL}/vehicle/vehicle-range`
export const vehicle_details = `${BaseURL}/vehicle/web/`

//*********************User Ev Experiance API URL's*********************//

export const add_user_owned_vehicles = `${BaseURL}/user/owned/vehicle/web`;
export const upload_images_for_ev_experiance = `${BaseURL}/user/owned/vehicle/web/uploads/`;
export const Get_owned_vehicles_with_experiances = `${BaseURL}/user/owned/vehicle/web/`;
export const delete_ev_experiance = `${BaseURL}/user/owned/vehicle/web/deletebytopic/`

//*********************User Post API URL's*********************//

export const add_user_post = `${BaseURL}/user/post/web`;
export const add_comment = `${BaseURL}/user/post/comment`;
export const get_comments = `${BaseURL}/user/post/comment`;
export const add_upvote_downvote = `${BaseURL}/user/post/add/upvote_downvote/web/`;

//*********************News and Blogs API URL's*********************//

export const Get_News = `${BaseURL}/news/all/website`;
export const Get_Feature_News = `${BaseURL}/news/website/featured-news?other=`;
export const Get_Random_News = `${BaseURL}/news/all/website/random`;
export const Get_Similar_Blogs = `${BaseURL}/tags/similar-blogs/web?`;
export const Get_Similar_News = `${BaseURL}/tags/similar-news/web?`;
export const Get_News_Details = `${BaseURL}/news/web/details`;
export const Get_News_Details_By_Slug = `${BaseURL}/news/website/news-by-slug`;
export const Get_Blog = `${BaseURL}/blog/web/all`;
export const Get_Random_Blog = `${BaseURL}/blog/web/random`;
export const Get_Blog_Details = `${BaseURL}/blog/web/details`;
export const Get_Blog_Details_By_Slug = `${BaseURL}/blog/web/blog-by-slug`;
export const Get_Rss_News = `${BaseURL}/news/website/rss`;
export const Get_Rss_Blog = `${BaseURL}/blog/web/rss`;

export const social_login = `${BaseURL}/user/social-signin`;

//*********************DEALER API*********************//
export const get_dealer_vehicle = `${BaseURL}/dealer/vehicle/dealer-panel/all`;
export const add_vehicle_dealer = `${BaseURL}/dealer/vehicle/dealer-panel`;
export const delete_dealer_vehicle = `${BaseURL}/dealer/vehicle/dealer-panel`;
export const clone_location = `${BaseURL}/general/clonelocation`;
export const Dealer_Posts = `${BaseURL}/user/post/dealer-panel`;
export const add_dealer_post = `${BaseURL}/user/post/dealer-panel`;
export const get_dealer_photo = `${BaseURL}/dealer-gallery/list-photo?other=`;
export const add_dealer_photo = `${BaseURL}/dealer-gallery/add-photo`;
export const delete_dealer_photo = `${BaseURL}/dealer-gallery/photo-delete`;
export const get_dealer_review = `${BaseURL}/dealer-reviews/web/get-all`;
export const add_dealer_review = `${BaseURL}/dealer-reviews`;
export const get_dealer_review_by_id = `${BaseURL}/dealer-reviews`;
export const get_dealer_video = `${BaseURL}/dealer-gallery/list-video?other=`;
export const add_dealer_video = `${BaseURL}/dealer-gallery/add-video`;
export const delete_dealer_video = `${BaseURL}/dealer-gallery/video-delete`;
export const like_dislike_review = `${BaseURL}/dealer-reviews/web/like-dislike/`;
export const get_dealer_brand = `${BaseURL}/dealer-reviews/web/list-dealer-brands?dealer=`;
export const add_dealer_brand = `${BaseURL}/dealer-brands/web/add`;
export const new_get_dealer_brand = `${BaseURL}/dealer-brands/web/list-brands/`;
export const delete_dealer_brand = `${BaseURL}/dealer-brands/web/delete-brand/`;


//*********************EVENT API*********************//
export const get_event_details = (companyId) => `${BaseURL}/event/web/event-details/${companyId}`
export const get_event_list = `${BaseURL}/event/web/event-list`


//*********************BARND GALLERY*********************//
export const get_brand_images = `${BaseURL}/gallery/web/list-photos?`;
export const get_brand_videos = `${BaseURL}/gallery/web/list-video?`;


//*********************PUBLIC API'S*********************//
export const public_get_dealer_brand = `${BaseURL}/dealer-brands/public/web/list-brands/`;
export const public_get_dealer_vehicle = `${BaseURL}/dealer/vehicle/public/all-web?dealer=`;
export const public_get_dealer_photo = `${BaseURL}/dealer-gallery/public/web/list-photos?other=`;
export const public_get_dealer_video = `${BaseURL}/dealer-gallery/public/web/list-video?other=`;
export const public_get_dealer_reviews = `${BaseURL}/dealer-reviews/public/web/get-all`;
export const public_get_dealer_post = `${BaseURL}/user/post/public/dealer-panel`;
export const public_Get_owned_vehicles_with_experiances = `${BaseURL}/user/owned/vehicle/public/web/`;
export const public_User_Posts = `${BaseURL}/user/public/detail/post?id=`;
export const public_Get_Owned_Vehicles = `${BaseURL}/user/public/detail/owned/vehicle?id=`;
export const public_User_Reviews = `${BaseURL}/user/public/detail/review?id=`;



//*********************Profile Tracking*********************//

export const profile_tracker = `${BaseURL}/user/profile-track`;

//*********************Filter APIs*********************//

export const Get_Filtered_Vehicle = `${BaseURL}/home/vehicle/filter`;
export const Get_Filtered_Company = `${BaseURL}/home/company/filter`;


//*****************************SUBMIT CONTACT US FORM*************************//

export const Send_Contact_Us_Form = `${BaseURL}/contact-us/add`


//*********************Listing APIs*********************//

export const Get_All_Company_List_Without_Pagination = `${BaseURL}/general/company`;
export const Get_Main_Vehicles = `${BaseURL}/general/vehicle/main`;
export const Get_User_Dealer_Listing = `${BaseURL}/user/listing`;

//*****************************Home page Api's*************************//

export const Get_Latest_Vehicle = `${BaseURL}/home/latest-vehicles/v2`;
export const Get_Popular_Vehicle = `${BaseURL}/home/popular-vehicles/v2`;
export const Get_Latest_Electric_Scooter = `${BaseURL}/home/latest-scooters/v2`;
export const Get_Latest_Electric_Bike = `${BaseURL}/home/latest-bikes/v2`;
export const Get_Latest_Electric_Car = `${BaseURL}/home/latest-cars/v2`;
export const Get_Latest_Commercials = `${BaseURL}/home/latest-commercials/v2`;
export const Get_Popular_Vehicle_Compare = `${BaseURL}/compare/web`;
export const Get_Faq_List = `${BaseURL}/qna/list/web`;
export const GET_FAQ_LIST_BY_COMPANY = `${BaseURL}/qna/company/list?`;
export const Get_Latest_reviews = `${BaseURL}/home/latest-reviews/v2`;
export const Get_Latest_Blog = `${BaseURL}/home/latest-blogs/v2`;
export const Get_Latest_Blog_News = `${BaseURL}/homepage-lp/latest-blogs-news`;
export const send_report = `${BaseURL}/report/add/web`

//*********************Home Page Background Banner Image*********************//

export const Get_HomePage_Banner_Image = `${BaseURL}/homepage-slider/list/web`

//*********************Home Page LandingPage Background Banner Image*********************//

export const Get_HomePage_Landingpage_Banner_Image = `${BaseURL}/homepage-lp/banner?`


//*********************Nearby city API*********************//

export const Get_Nearby_Cities = `${BaseURL}/general/nearbycities/`;
export const Get_Nearby_By_Coordinates = `${BaseURL}/latestcity/nearby/city`;
export const Get_City_By_Coordinates = `${BaseURL}/latestcity/city/coordinates`;
export const Add_Nearby_Cities = `${BaseURL}/test/script/nearby/`;

//*********************OLA leads*********************//

export const Ola_Leads = `${BaseURL}/olaleads/`

//*********************Header Searches*********************//

export const Header_searches = `${BaseURL}/home/header-search`

//*********************Career Api's*********************//

export const Get_Jobs = `${BaseURL}/career`
export const Get_Job_Details = `${BaseURL}/career/web/details/`
export const add_candidate = `${BaseURL}/career/add/candidate`

//*********************Dealer Dashboard Api's*********************//

export const get_dealer_lead = `${BaseURL}/dealer-dashboard/lead/web/list-dealer-leads`
export const get_dealer_crm = `${BaseURL}/dealer-dashboard/crm/web/details`
export const get_dealer_wallet = `${BaseURL}/dealer-dashboard/wallet/web/list`
export const dealer_comment = `${BaseURL}/dealer-dashboard/lead/web/add-edit-comment`
export const set_lead_status = `${BaseURL}/dealer-dashboard/lead/web/change-status`
export const download_lead_csv = `${BaseURL}/dealer-dashboard/lead/web/download/csv`

//*********************Popular Vehicle Images Api*********************//

export const Get_Popular_Images = `${BaseURL}/home/popular-vehicle-image`;

//*********************Landing Page Api*********************//

export const GET_LP_DETAILS = `${BaseURL}/landing-page/details`;
